import React from 'react'
import { Link } from 'gatsby'
import Headroom from 'react-headroom'
import styled from '@emotion/styled'
import logo from '../../../contents/static/logo/not-be-forgotten.png'

const StyledLogo = styled.img`
  width: 48px;
  height: 48px;
`

const NavBar = () => {
  return (
    <Headroom calcHeightOnResize disableInlineStyles>
      <Link to='/'>
        <StyledLogo src={logo} alt='logo' />
      </Link>
      <nav>
        <Link to='/about'>About</Link>
        <Link to='/tags'>Tag</Link>
      </nav>
    </Headroom>
  )
}

export default NavBar