import React from 'react'
import PropTypes from 'prop-types'
import ThemeProvider from './ThemeProvider'

import Global from './Global'
import NavBar from './NavBar'
import Footer from './Footer'
import SEO from '../Organisms/SEO'

const Layout = ({ children, seo }) => (
  <ThemeProvider>
    <Global />
    <SEO {...seo}/>
    <NavBar />
    {children}
    <Footer />
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node
}

export default Layout