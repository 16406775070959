import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const Wrapper = styled.header`
  height: 300px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: 300px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: 275px;
  }
  position: relative;
  overflow: hidden;
`

const Text = styled.div`
  color: ${props => props.theme.colors.text.base};
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  padding: 0 2rem;
  margin-bottom: 3rem;
  align-items: center;
`

const Subtitle = styled.p`
  max-width: 650px;
  color: ${props => props.theme.colors.text.base};
`

const DateText = styled.div`
width: 100%;
text-align: right;
`
const Header = ({ children, title, created_at, updated_at, cover }) => (
  <Wrapper>
    <Img fluid={cover || {} || [] || ''} alt={title} />
    <Text>
      <h1>{title}</h1>
      {created_at && <DateText>作成日: {created_at}</DateText>}
      {updated_at && <DateText>更新日: {updated_at}</DateText>}
      {children && <Subtitle>{children}</Subtitle>}
    </Text>
  </Wrapper>
)

export default Header

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  cover: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool
  ])
}

Header.defaultProps = {
  children: false,
  cover: false,
  date: false,
  title: false
}