import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider as EmotionProvider } from 'emotion-theming'
import { getTheme } from '../../../config/theme'

const ThemeProvider = ({ children }) => {
  return (
    <EmotionProvider theme={getTheme()}>
      {children}
    </EmotionProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default ThemeProvider